import 'vanilla-cookieconsent';

import * as deepMerge from 'deepmerge';

interface O2GdprSettings {
  o2: any;
  cc: any;
}

interface CC {
  // accept: (a,b) => void,
  // allowedCategory: ƒ (a),
  // eraseCookies: ƒ (a,b, c),
  // get: ƒ (a,b),
  // getConfig: ƒ (a),
  getUserPreferences: () => {
    accept_type: string;
    accepted_categories: Array<string>;
    rejected_categories: Array<string>;
  };
  // hide: ƒ (),
  // hideSettings: ƒ (),
  // loadScript: ƒ (a,b,c),
  // run: ƒ (a),
  // set: ƒ (a,b),
  // show: ƒ (a),
  // showSettings: ƒ (a),
  // updateLanguage: ƒ (a,b),
  // updateScripts: ƒ (),
  // validCookie: ƒ (a),
}

interface O2 {
  gdpr: {
    settings: O2GdprSettings;
    exponea: any;
    init: (options: any) => void;
    getInstance: () => CC;
    getConsentLevel: () => string;
    recalculateOptInCookie: (callback?: () => void) => void;
    clearCookiesAndStorage: () => void;
    logRequest: () => void;
  };
}

declare const O2: O2;

interface Cookies {
  get: (key: string) => string | undefined;
  set: (key: string, value: any, attributes?: any) => string;
  remove: (key: string, attributes?: any) => void;
}

declare const Cookies: Cookies;

interface O2Window extends Window {
  O2: O2;
  Cookies: Cookies;
  initCookieConsent: () => any;
  exponea: any;
  dataLayer: [{ event: string }];
}

declare const window: O2Window;

(function () {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.O2 = window.O2 || {};
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.O2.gdpr = window.O2.gdpr || {};

  let cc: any;

  let cookieConfig = {};

  let currentCookieAction = '';

  const defaults = {
    cc: {
      gui_options: {
        consent_modal: {
          layout: 'box',
          position: 'middle center',
          transition: 'slide',
          swap_buttons: false,
        },
        settings_modal: {
          layout: 'box',
          transition: 'slide',
        },
      },

      revision: 1,
      current_lang: 'sk',
      cookie_name: 'X-O2SK-GDPR-CC',
      cookie_expiration: 50 * 365,
      cookie_path: '/',
      cookie_domain: location.hostname, // musí byť podľa existujúceho stavu
      cookie_same_site: 'Lax',
      autoclear_cookies: true,
      hide_from_bots: true,

      onFirstAction: function (userPreferences: any, cookie: any) {
        currentCookieAction = 'first';

        O2.gdpr.logRequest();
        O2.gdpr.recalculateOptInCookie();

        const ccDiv = document.getElementById('cc_div');
        ccDiv &&
          ccDiv.dispatchEvent(
            new CustomEvent('o2Init', {
              detail: {
                cookie: cookie,
              },
            })
          );
      },

      onChange: function (cookie: any) {
        currentCookieAction = 'change';
        O2.gdpr.logRequest();

        if (
          cookie.categories.length === 1 &&
          cookie.categories.includes('necessary')
        ) {
          O2.gdpr.clearCookiesAndStorage();
        }

        O2.gdpr.recalculateOptInCookie();

        const ccDiv = document.getElementById('cc_div');
        ccDiv &&
          ccDiv.dispatchEvent(
            new CustomEvent('o2Change', {
              detail: {
                cookie: cookie,
              },
            })
          );
      },

      languages: {
        sk: {
          consent_modal: {
            title: 'Súhlas s cookies',
            description:
              'Na zlepšenie našich služieb používame cookies. O ich používaní a možnostiach nastavenia sa dozviete viac v <a href="https://www.o2.sk/podpora/centrum-podpory/ako-nakupovat/ochrana-sukromia" target="_blank">Zásadách ochrany osobných údajov</a>. Bez vášho súhlasu používame iba technické cookies nevyhnutné pre fungovanie stránky. Súhlas so spustením marketingových a analytických cookies udelíte kliknutím na "Súhlasím a pokračovať". <button type="button" data-cc="c-settings" class="cc-link cc-link-bn">Podrobné nastavenia cookies</button>',
            primary_btn: {
              text: 'Súhlasím a pokračovať',
              role: 'accept_all',
            },
            secondary_btn: {
              text: 'Zamietnuť cookies',
              role: 'accept_necessary',
            },
          },
          settings_modal: {
            title: 'Súhlas s cookies',
            save_settings_btn: 'Uložiť nastavenia',
            accept_all_btn: 'Akceptovať všetky',
            blocks: [
              {
                description:
                  'Na zlepšenie našich služieb používame cookies. O ich používaní a možnostiach nastavenia sa dozviete viac v Zásadách ochrany osobných údajov. Bez vášho súhlasu používame iba technické cookies nevyhnutné pre fungovanie stránky.',
              },
              {
                title: 'Technické cookies',
                description:
                  'Technické cookies sú nevyhnutné, aby na webe všetko fungovalo, ako má. Či už si chcete vybaviť rýchly nákup cez e-shop, alebo len zistiť svoju spotrebu cez Moje O2. Tieto cookies preto nie je možné zakázať.',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true,
                },
              },
              {
                title: 'Analytické cookies',
                description:
                  'Vďaka analytickým cookies vieme lepšie porozumieť správaniu návštevníkov na našich webových stránkach a optimalizovať ich pre čo najlepšiu užívateľskú skúsenosť.',
                toggle: {
                  value: 'analytics',
                  enabled: false,
                  readonly: false,
                },
              },
              {
                title: 'Marketingové cookies',
                description:
                  'Vďaka marketingovým cookies vieme optimálne merať a zobrazovať reklamný obsah tak, aby bol pre vás zaujímavý a relevantný.',
                toggle: {
                  value: 'targeting',
                  enabled: false,
                  readonly: false,
                },
              },
              {
                description:
                  '<a class="cc-link" href="https://www.o2.sk/podpora/centrum-podpory/ako-nakupovat/ochrana-sukromia/cookies" target="_blank">Viac informácií o cookies</a>',
              },
            ],
          },
        },
      },
    },
    o2: {
      // konfigurácia špecifická pre o2 skripty
      cookieNames: {
        source: 'X-O2SK-GDPR-SOURCE',
        optIn: 'X-O2SK-GDPR-OPTIN',
        gdprId: 'X-O2SK-GDPR-ID',
        exponea: '__exponea_etc__',
        luigisBox: 'lbx_consent_cookie',
      },
      exponea: {
        cookies_add: 'Súhlas s použitím cookies',
        cookies_remove: 'Odobratie súhlasu s použitím cookies',
        mkt_add: 'Súhlas so spracovaním údajov na marketingové účely',
        mkt_remove:
          'Odobratie súhlasu so spracovaním údajov na marketingové účely',
      },
      portalURL: 'https://www.o2.sk',
      isAssistantLoggedIn: false,
      isStoreDockbar: false,
      // privacy urls just for information purpose. Default value is defined inside settings_modal block. But you can change them from outside with these properties.
      privacyPolicyUrl:
        'https://www.o2.sk/podpora/centrum-podpory/ako-nakupovat/ochrana-sukromia',
      processingPolicyUrl:
        'https://www.o2.sk/podpora/centrum-podpory/ako-nakupovat/ochrana-sukromia/cookies',
    },
  };

  O2.gdpr.settings = {
    cc: {},
    o2: {},
  };

  O2.gdpr.init = (options) => {
    if (options?.cc?.languages?.sk?.settings_modal?.blocks) {
      // @ts-ignore
      delete defaults.cc.languages.sk.settings_modal.blocks;
    }

    O2.gdpr.settings = deepMerge(defaults, options);

    setPolicyUrl(options);
    cookieConfig = setCookieConfig();

    cc = window.initCookieConsent();

    // if old cookie exists, run migration
    if (Cookies.get('X-O2SK-GDPR-CONSENT') === 'allow') {
      O2.gdpr.settings.cc.autorun = false;
      cc.run(O2.gdpr.settings.cc);
      cc.accept('all');
      Cookies.remove('X-O2SK-GDPR-CONSENT');
    } else if (
      O2.gdpr.settings.o2.isAssistantLoggedIn ||
      O2.gdpr.settings.o2.isStoreDockbar
    ) {
      // if assitent is logged in, set only necessary cookies

      if (getConsentLevel() !== 'none') {
        O2.gdpr.recalculateOptInCookie();
        O2.gdpr.settings.cc.autorun = false;
        cc.run(O2.gdpr.settings.cc);
        cc.accept('necessary');
      } else {
        O2.gdpr.recalculateOptInCookie();
        cc.run(O2.gdpr.settings.cc);
      }
    } else {
      O2.gdpr.recalculateOptInCookie();
      cc.run(O2.gdpr.settings.cc);
    }

    setDataO2Attributes();

    return cc;
  };

  function getCurrentCookieCategories(): Array<string> {
    const cookieData = Cookies.get(O2.gdpr.settings.cc.cookie_name);

    if (cookieData) {
      return JSON.parse(cookieData).categories;
    }

    return [];
  }

  O2.gdpr.getInstance = (): CC => {
    return cc;
  };

  O2.gdpr.recalculateOptInCookie = function (callback) {
    const cookieNames = O2.gdpr.settings.o2.cookieNames;
    let optIn = 'none';

    const source = Cookies.get(cookieNames.source);
    if (source === 'web') {
      optIn = getConsentLevel();
    }

    Cookies.set(cookieNames.optIn, optIn, cookieConfig);

    if (optIn === 'analytics' || optIn === 'all') {
      Cookies.set(cookieNames.luigisBox, 'granted', cookieConfig);
    } else {
      Cookies.remove(cookieNames.luigisBox, cookieConfig);
    }

    if (callback) {
      callback();
    }
  };

  O2.gdpr.getConsentLevel = getConsentLevel;

  function getConsentLevel(): string {
    let level = '';

    // get categories from cookie not from instance (cc.getUserPreferences().accepted_categories), because it can be not initialized yet
    const acceptedCategories = getCurrentCookieCategories();

    if (
      acceptedCategories.length === 0 ||
      isEqualArray(acceptedCategories, ['necessary'])
    ) {
      level = 'none';
    } else if (
      isEqualArray(acceptedCategories, ['necessary', 'analytics', 'targeting'])
    ) {
      level = 'all';
    } else if (isEqualArray(acceptedCategories, ['necessary', 'analytics'])) {
      level = 'analytics';
    } else if (isEqualArray(acceptedCategories, ['necessary', 'targeting'])) {
      level = 'targeting';
    }

    return level;
  }

  function isEqualArray(arr1: Array<string>, arr2: Array<string>) {
    return JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort());
  }

  O2.gdpr.clearCookiesAndStorage = function () {
    localStorage.clear();
    sessionStorage.clear();

    const cookies = document.cookie.split('; ');

    // remove all cookies but CC
    for (const cookie of cookies) {
      const pos = cookie.indexOf('=');
      const name = pos > -1 ? cookie.substring(0, pos) : cookie;

      if (name !== O2.gdpr.settings.cc.cookie_name) {
        Cookies.remove(name, cookieConfig);
      }
    }
  };

  O2.gdpr.logRequest = function () {
    const payload = {
      domain: O2.gdpr.settings.cc.cookie_domain,
      content: O2.gdpr.settings.cc.languages.sk,
      cookie: Cookies.get(O2.gdpr.settings.cc.cookie_name),
      // add referenceId only if that cookie exists
      referenceId: Cookies.get(O2.gdpr.settings.o2.gdprId) || undefined,
    };

    fetch(`${O2.gdpr.settings.o2.portalURL}/delegate/cookie-consent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((result) => successCallback(result))
      .catch((error) => failureCallback(error));
  };

  function successCallback(data: string) {
    Cookies.set(O2.gdpr.settings.o2.cookieNames.source, 'web', cookieConfig);

    // Cookie X-O2SK-GDPR-ID sa nastaví len v prípade, ak ešte neexistuje.
    if (Cookies.get(O2.gdpr.settings.o2.cookieNames.gdprId) === undefined) {
      Cookies.set(O2.gdpr.settings.o2.cookieNames.gdprId, data, cookieConfig);
    }

    // DEV-55954 send event after logRequest successfuly ended
    O2.gdpr.recalculateOptInCookie(() => {
      if (currentCookieAction === 'first') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'cookie_consent_saved',
        });
      } else if (currentCookieAction === 'change') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'cookie_consent_changed',
        });
      }
    });

    const acceptedAll = cc.getUserPreferences().accept_type === 'all';
    O2.gdpr.exponea.callCookiesChange(acceptedAll, window.location.href);
  }

  function failureCallback(error: any) {
    console.error('[GDPR]: Error occurred during fetch request: ' + error);
  }

  function handleErrors(response: Response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  function setCookieConfig() {
    return {
      domain: O2.gdpr.settings.cc.cookie_domain,
      path: O2.gdpr.settings.cc.cookie_path,
      expires: O2.gdpr.settings.cc.cookie_expiration,
      httpOnly: false,
      secure: false,
      samesite: O2.gdpr.settings.cc.cookie_same_site,
    };
  }

  function setPolicyUrl(options: O2GdprSettings) {
    if (options.o2.privacyPolicyUrl) {
      O2.gdpr.settings.cc.languages[
        'sk'
      ].settings_modal.blocks[4].description = `<a href="${options.o2.privacyPolicyUrl}" class="cc-link">Viac informácií o cookies</a>`;
    }

    if (options.o2.processingPolicyUrl) {
      O2.gdpr.settings.cc.languages[
        'sk'
      ].consent_modal.description = `Na zlepšenie našich služieb používame cookies. O ich používaní a možnostiach nastavenia sa dozviete viac v <a href="${options.o2.processingPolicyUrl}">Zásadách ochrany osobných údajov</a>. Bez vášho súhlasu používame iba technické cookies nevyhnutné pre fungovanie stránky. Súhlas so spustením marketingových a analytických cookies udelíte kliknutím na "Súhlasím a pokračovať"`;
    }
  }

  /*
   * Add data attributes for automation
   */
  function setDataO2Attributes(): void {
    const allow = document.getElementById('c-p-bn');
    const allowAll = document.getElementById('s-all-bn');
    const allowCustom = document.getElementById('s-sv-bn');

    allow && allow.setAttribute('data-o2-id', 'cookieConsent.allow');
    allowAll && allowAll.setAttribute('data-o2-id', 'cookieConsent.allowAll');
    allowCustom &&
      allowCustom.setAttribute('data-o2-id', 'cookieConsent.allowCustom');
  }

  O2.gdpr.exponea = {
    callCookiesChange: function (approved: any, url: any, ip?: any) {
      if (window.exponea) {
        const settings = O2.gdpr.settings;
        const texts = settings.o2.exponea;
        window.exponea.track('consent', {
          location: url,
          action: approved ? 'accept' : 'withdrawn',
          category_label: 'prehliadacovy_suhlas',
          ip: ip,
          message: approved ? texts.cookies_add : texts.cookies_remove,
          exponea_cookie: Cookies.get(settings.o2.cookieNames.exponea),
        });
      } else {
        console.warn('[GDPR]: cannot execute exponea tracking');
      }
    },
    callMktChange: function (approved: any, url: any, ip: any) {
      if (window.exponea) {
        const settings = O2.gdpr.settings;
        const texts = settings.o2.exponea;
        window.exponea.track('consent', {
          location: url,
          action: approved ? 'accept' : 'withdrawn',
          category_label: 'ucastnicky_mkt_suhlas',
          ip: ip,
          message: approved ? texts.mkt_add : texts.mkt_remove,
          exponea_cookie: Cookies.get(settings.o2.cookieNames.exponea),
        });
      } else {
        console.warn('[GDPR]: cannot execute exponea tracking');
      }
    },
  };
})();
